import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { BooleanSelection } from '../../QuestionTypes';
import { BooleanQuestionProps } from '../interfaces';

export const CompensationForHouseholdQuestion: React.FC<BooleanQuestionProps> = (props) => {
  const t = useTranslations();

  return (
    <BooleanSelection
      errorMessage={props.error ? t('hasReceivedCompensationForHouseholdError') : undefined}
      title={t('hasReceivedCompensationForHouseholdQuestion')}
      name={Field.HasReceivedCompensationForHousehold}
      trueLabel={t('yesOption')}
      falseLabel={t('noOption')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
