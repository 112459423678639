import * as React from 'react';
import { TextQuestion } from '@scout24ch/fs24-design-system';
import { useTrackOnMount } from '@scout24ch/fs24-gtm-react';
import { DataLayerGtmEvent } from '../../../config/gtm/helpers';
import { Field } from '../../../enums';
import { useTranslations, useUser } from '../../../hooks';

interface EmailAddressQuestionProps {
  error: string | null;
  currentValue: string | null;
  onValueChange: (newValue: string) => void;
  onBlur: () => void;
  eventOnLoad?: DataLayerGtmEvent;
}

export const EmailAddressQuestion: React.FC<EmailAddressQuestionProps> = (props) => {
  const t = useTranslations();
  const { isUserLoggedIn } = useUser();
  useTrackOnMount(props.eventOnLoad as DataLayerGtmEvent);

  return (
    <TextQuestion
      name={Field.EmailAddress}
      label={t('emailAddressLabel')}
      description={t('emailAddressIntroLabel')}
      type="text"
      value={props.currentValue || ''}
      onValueChange={props.onValueChange}
      onBlur={props.onBlur}
      errorMessage={props.error ? t('emailAddressError') : undefined}
      isDisabled={isUserLoggedIn}
    />
  );
};
