import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { getPriceOptions } from '../../InquirySections/HouseholdContentSection/getPriceOptions';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const ClothingPriceQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const clothingPriceOptions = getPriceOptions(
    t('clothingPriceLowLabel'),
    t('clothingPriceMediumLabel'),
    t('clothingPriceHighLabel'),
  );

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('clothingPriceError') : undefined}
      name={Field.Clothing}
      title={t('clothingPriceQuestion')}
      intro={t('clothingPriceIntro')}
      options={clothingPriceOptions}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
