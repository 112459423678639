import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { BooleanSelection } from '../../QuestionTypes';
import { BooleanQuestionProps } from '../interfaces';

export const IsGroundFloorQuestion: React.FC<BooleanQuestionProps> = (props) => {
  const t = useTranslations();

  return (
    <BooleanSelection
      errorMessage={props.error ? t('generalError') : undefined}
      name={Field.IsGroundFloorApartment}
      trueLabel={t('yesOption')}
      falseLabel={t('noOption')}
      title={t('isGroundFloorApartmentQuestion')}
      intro={t('isGroundFloorApartmentIntro')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
