import * as React from 'react';
import { SelectQuestionOption, TextQuestion } from '@scout24ch/fs24-design-system';
import { getCities } from '../../../api/locations';
import { Canton, Field, PostalCodeError } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { City, CityError } from '../../../types';
import { asInt } from '../../../utils';
import { SelectInput } from '../../QuestionTypes';
import { RenderIf } from '../../RenderIf';

export interface PostalCodeCityInputProps {
  city: City;
  onChange: (city: City, nextField?: string) => void;
  error: CityError;
  onError: (error: CityError) => void;
  nextField: string;
}

const hasMoreCities = (cityOptions: SelectQuestionOption[]) => cityOptions.length >= 3;

const createCitySelectOptions = (cities: City[], placeholder: string) => {
  const cityOptions = cities.map((city) => ({
    value: city.name || '',
    label: city.name || '',
  }));
  return [{ value: '', label: placeholder, disabled: true }, ...cityOptions];
};

const createCantonSelectOptions = (placeholder: string) => {
  const cantons = Object.keys(Canton).map((canton) => ({
    value: canton,
    label: canton,
  }));
  return [{ value: '', label: placeholder }, ...cantons];
};

const displayCity = (show: boolean, cityOptions: SelectQuestionOption[], city: City) => {
  if (show || cityOptions.length === 1) {
    return '';
  }

  const shouldRenderCity = city.postalCode && city.postalCode > 999;
  return shouldRenderCity ? `${city.name || ''} ${city.canton || ''}` : '';
};

export const PostalCodeQuestion: React.FC<PostalCodeCityInputProps> = (props) => {
  const t = useTranslations();

  const [multipleCities, setMultipleCities] = React.useState<City[]>([]);
  const [cityOptions, setCityOptions] = React.useState<SelectQuestionOption[]>([]);
  const [requestFailed, setRequestFailed] = React.useState<boolean>(false);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const onPostalCodeChange = async (value: string) => {
    props.onChange({ ...props.city, postalCode: asInt(value) });
    if (value && value.length == 4) {
      try {
        setIsFetching(true);
        const cities: City[] = await getCities(asInt(value));
        const city: City = {
          ...props.city,
          postalCode: asInt(value),
        };
        if (cities.length === 0) {
          props.onError({ ...props.error, postalCode: PostalCodeError.NotFoundCode });
        } else if (cities.length === 1) {
          city.name = cities[0].name;
          city.canton = cities[0].canton;
          props.onChange(city, Field.IsMassive);
          props.onError({ ...props.error, postalCode: null });
        } else if (cities.length > 1) {
          city.name = '';
          city.canton = '';
          props.onChange(city, Field.City);
          props.onError({ ...props.error, postalCode: null });
        }
        setMultipleCities(cities);
        setCityOptions(createCitySelectOptions(cities, t('selectLabel')));
        setRequestFailed(false);
      } catch (err) {
        if (err.message.includes('404')) {
          props.onError({ ...props.error, postalCode: PostalCodeError.NotFoundCode });
          const city: City = {
            name: '',
            canton: '',
            postalCode: asInt(value),
          };
          props.onChange(city, Field.City);
        } else {
          setRequestFailed(true);
        }
      }
      setIsFetching(false);
    } else {
      setCityOptions([]);
    }
  };

  const handleCityChange = (currentCity: City, newName: string) => {
    const city: City = { ...currentCity, name: newName, canton: '' };
    for (const c of multipleCities) {
      if (c.name === newName && c.postalCode === city.postalCode) {
        city.canton = c.canton;
        break;
      }
    }
    props.onChange(city, props.nextField);
  };

  return (
    <>
      <div className="postal-code">
        <TextQuestion
          label={t('postalCode')}
          maxWidth="50%"
          name="postal-code"
          errorMessage={props.error.postalCode ? t(props.error.postalCode) : undefined}
          value={String(props.city.postalCode || '')}
          onValueChange={onPostalCodeChange}
        />

        <p>{displayCity(requestFailed || isFetching, cityOptions, props.city)}</p>
      </div>
      <div className="scroll-checkpoint" data-scroll={Field.PostalCode} />
      <RenderIf condition={hasMoreCities(cityOptions)}>
        <SelectInput
          title={t('cityInputLabel')}
          errorMessage={props.error.name ? t('cityError') : undefined}
          name="city-select"
          options={cityOptions}
          onChange={(newValue) => handleCityChange(props.city, newValue as any)}
          value={props.city.name || ''}
        />
        <div className="scroll-checkpoint" data-scroll={Field.City} />
      </RenderIf>

      {/* Suppose the location service is down, let user to type in the place manually */}
      <RenderIf condition={requestFailed}>
        <TextQuestion
          label={t('cityInputLabel')}
          name="city"
          type="text"
          errorMessage={props.error.name ? t('cityError') : undefined}
          value={props.city.name || ''}
          onValueChange={(value) => props.onChange({ ...props.city, name: value }, 'canton')}
        />
        <RenderIf condition={props.city.name !== null}>
          <SelectInput
            title={t('cantonInputLabel')}
            errorMessage={props.error.canton ? t('cantonError') : undefined}
            name="canton-select"
            options={createCantonSelectOptions(t('selectLabel'))}
            onChange={(newValue) => props.onChange({ ...props.city, canton: newValue as any }, props.nextField)}
            value={props.city.canton || ''}
          />
        </RenderIf>
      </RenderIf>
    </>
  );
};
