import * as React from 'react';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes';
import { DropDownQuestionProps } from '../interfaces';
import { Field } from '../../../enums';

const MAX_INSUREES = 8;

export const NumberOfInsureesQuestion: React.FC<DropDownQuestionProps> = (props) => {
  const t = useTranslations();

  const numberOfInsureesOptions: SelectQuestionOption[] = [
    { value: '', label: t('selectLabel'), disabled: true },
    { value: '1', label: `1 ${t('personSingular')}` },
    ...Array.from(Array(MAX_INSUREES), (x, i) => ({
      value: String(i + 2),
      label: `${i + 2} ${t('personPlural')}`,
    })),
  ];

  return (
    <SelectInput
      errorMessage={props.error ? t('numberOfInsureesError') : undefined}
      name={Field.NumberOfInsurees}
      title={t('numberOfInsureesQuestion')}
      options={numberOfInsureesOptions}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
