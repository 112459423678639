import * as React from 'react';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes/SelectInput';
import { DropDownQuestionProps } from '../interfaces';
import { Field, InsuranceCompany } from '../../../enums';

export const PreviousInsurerQuestion: React.FC<DropDownQuestionProps> = (props) => {
  const t = useTranslations();

  const previousInsurerOptions: SelectQuestionOption[] = [
    { value: '', label: t('selectDefaultLabel'), disabled: true },
    {
      value: InsuranceCompany.NoPreviousInsuranceCompany,
      label: t(InsuranceCompany.NoPreviousInsuranceCompany),
    },
    { value: InsuranceCompany.Allianz, label: t('allianz') },
    { value: InsuranceCompany.Axa, label: t('axa') },
    { value: InsuranceCompany.Basler, label: t('basler') },
    { value: InsuranceCompany.Mobiliar, label: t('mobiliar') },
    { value: InsuranceCompany.Elvia, label: t('elvia') },
    { value: InsuranceCompany.Generali, label: t('generali') },
    { value: InsuranceCompany.Helvetia, label: t('helvetia') },
    { value: InsuranceCompany.Smile, label: t('smile') },
    { value: InsuranceCompany.Vaudoise, label: t('vaudoise') },
    { value: InsuranceCompany.Visana, label: t('visana') },
    { value: InsuranceCompany.Zurich, label: t('zurich') },
    { value: InsuranceCompany.Other, label: t('other') },
  ];

  return (
    <SelectInput
      title={t('previousInsurerQuestion')}
      errorMessage={props.error ? t('previousInsurerError') : undefined}
      options={previousInsurerOptions}
      name={Field.PreviousInsurer}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
