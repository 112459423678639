import { validateDateIfMoreThanXMonths, validateDateIsInFuture } from '@scout24ch/fs24-forms';
import { DateError } from '../../../enums';

export const insuranceStartDateValidator = (insuranceStartDate: string, error: DateError | null) => {
  if (insuranceStartDate === 'NaN') {
    error = DateError.InvalidDateFormat;
  } else if (insuranceStartDate === '') {
    error = DateError.InsuranceStartDateMissing;
  } else {
    const insuranceDateIsInPast = validateDateIsInFuture(insuranceStartDate);
    const moreThanOneYearInTheFuture = validateDateIfMoreThanXMonths(insuranceStartDate, -12);
    if (insuranceDateIsInPast) {
      error = DateError.DateToDayOrInThePast;
    } else if (moreThanOneYearInTheFuture) {
      error = DateError.DateOverOneYearInTheFuture;
    } else {
      error = null;
    }
  }
  return error;
};
