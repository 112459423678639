import { validateEmail, validateRequired } from '@scout24ch/fs24-forms';
import { range } from '@scout24ch/fs24-utils';
import { DateError, PostalCodeError } from '../../enums';
import { City, CityError, Damage } from '../../types';
import { asInt } from '../../utils';

const MAX_DAMAGE_AMOUNT = 5000000;
const MIN_DAMAGE_AMOUNT = 10;

export const fieldHasError = (field: boolean) => {
  return field === true;
};

export const hasValue = (field: any) => {
  if (typeof field === 'string') {
    return field.trim() !== '';
  }
  return field !== null && field !== undefined && field !== '' && !Number.isNaN(field);
};

export const hasError = (field: any) => {
  return !hasValue(field);
};

export const damageAmountHasError = (
  amount: string | null | undefined,
  max = MAX_DAMAGE_AMOUNT,
  min = MIN_DAMAGE_AMOUNT,
) => {
  return hasError(amount) || asInt(amount) < min || asInt(amount) > max;
};

export const conditionalFieldIsValid = (
  first: boolean | null | undefined,
  second: string | number | null | undefined,
) => {
  return (first === true && hasValue(second)) || (first === false && second == null);
};

export const conditionalFieldHasError = (first: boolean | null, second: string | number | null | undefined) => {
  return !conditionalFieldIsValid(first, second);
};

export const numberOfChildrenIsValid = (numberOfInsurees: string | null, numberOfChildren: string | null) => {
  return (
    (asInt(numberOfInsurees) > 1 && hasValue(numberOfChildren)) ||
    (asInt(numberOfInsurees) === 1 && numberOfChildren === null)
  );
};

export const getChildrenBirthDatesErrors = (childrenDatesOfBirth: string[], errors: (DateError | null)[]) => {
  if (errors.length === 0) {
    errors = range(asInt(childrenDatesOfBirth.length), () => null);
  }

  return childrenDatesOfBirth.map((date, index) => (hasError(date) ? DateError.DateMissing : errors[index]));
};

export const getDateOfBirthError = (dateOfBirth: string | null | undefined, error: DateError | null) => {
  return hasError(dateOfBirth) ? DateError.DateOfBirthMissing : error;
};

export const getEmailError = (emailAddress: string | null | undefined) => {
  const error = validateEmail(String(emailAddress))?.code || validateRequired(String(emailAddress))?.code;
  return error || null;
};

export const getCityErrors = (city: City, errors: CityError) => {
  const updatedErrors = { ...errors };
  if (hasError(city.postalCode) || String(city.postalCode).length !== 4) {
    updatedErrors.postalCode = updatedErrors.postalCode ? updatedErrors.postalCode : PostalCodeError.InvalidCode;
  }
  return { ...updatedErrors, name: hasError(city.name), canton: hasError(city.canton) };
};

export const getDamageError = (damage: Damage) => {
  return {
    reason: hasError(damage.reason),
    year: hasError(damage.year),
    month: hasError(damage.month),
    amount: damageAmountHasError(damage.amount, MAX_DAMAGE_AMOUNT, MIN_DAMAGE_AMOUNT),
  };
};
