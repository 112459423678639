import * as React from 'react';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes';
import { DropDownQuestionProps } from '../interfaces';
import { Field, Size } from '../../../enums';

export const NumberOfRoomsQuestion: React.FC<DropDownQuestionProps> = (props) => {
  const t = useTranslations();

  const numberOfRoomsOptions: SelectQuestionOption[] = [
    { value: '', label: t('selectLabel'), disabled: true },
    { value: Size.One, label: '1' },
    { value: Size.OneAndHalf, label: '1.5' },
    { value: Size.Two, label: '2' },
    { value: Size.TwoAndHalf, label: '2.5' },
    { value: Size.Three, label: '3' },
    { value: Size.ThreeAndHalf, label: '3.5' },
    { value: Size.Four, label: '4' },
    { value: Size.FourAndHalf, label: '4.5' },
    { value: Size.Five, label: '5' },
    { value: Size.FiveAndHalf, label: '5.5' },
    { value: Size.Six, label: '6' },
    { value: Size.SixAndHalf, label: '6.5' },
    { value: Size.Seven, label: '7' },
    { value: Size.SevenAndHalf, label: '7.5' },
    { value: Size.Eight, label: '8' },
    { value: Size.EightAndHalf, label: '8.5' },
    { value: Size.Nine, label: '9' },
  ];

  return (
    <SelectInput
      title={t('numberOfRoomsQuestion')}
      errorMessage={props.error ? t('generalError') : undefined}
      options={numberOfRoomsOptions}
      name={Field.NumberOfRooms}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
