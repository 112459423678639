import * as React from 'react';
import { range } from '@scout24ch/fs24-utils';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes';
import { DropDownQuestionProps } from '../interfaces';
import { TranslationKey } from '../../../types';

interface MonthOfDamageQuestionProps {
  year: string;
  fieldName: string;
}

export const MonthOfDamageQuestion = (props: DropDownQuestionProps & MonthOfDamageQuestionProps) => {
  const t = useTranslations();

  const getMonthOptions = (selectedYear: string) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const numberOfMonths = parseInt(selectedYear, 10) < currentYear ? 12 : currentMonth + 1;
    return [
      { label: t('selectLabel'), value: '', disabled: true },
      ...range(numberOfMonths, (i) => ({
        label: t(`month${i + 1}` as TranslationKey),
        value: `${i + 1}`,
      })),
    ];
  };

  return (
    <SelectInput
      errorMessage={props.error ? t('dateError') : undefined}
      name={props.fieldName}
      title={t('monthQuestion')}
      options={getMonthOptions(props.year)}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
