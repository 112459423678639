import * as React from 'react';
import { Field } from '../../../enums';
import { useNOrMore, useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const NumberOfPrivateLiabilityCompensationsQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();
  const numberOfDamageCompensationsOptions = useNOrMore(4);

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('numberOfPrivateLiabilityDamageCompensationsError') : undefined}
      name={Field.NumberOfPrivateLiabilityDamageCompensations}
      title={t('numberOfDamageCompensationsQuestion')}
      options={numberOfDamageCompensationsOptions}
      currentValue={String(props.currentValue)}
      onChange={props.onChange}
    />
  );
};
