import { validateDateIsInFuture } from '@scout24ch/fs24-forms';
import { DEFAULT_SELF_INFLICTED_DAMAGES_AMOUNT, DEFAULT_THEFT_NOT_AT_HOME_AMOUNT } from '../common/constants';
import { DamageType, Language, ClaimType, Field } from '../enums';
import { PrivateLiabilityFields } from '../state/privateLiabilityInquiry/types';
import { Damage } from '../types';
import { PrivateLiabilityInquiryRequest } from '../types/PrivateLiabilityInquiryRequest';
import { Claim } from '../types/Requests';
import { asInt, getDefaultInsuranceStartDate } from '../utils';
import { getDamageType } from '../utils/createDamagesArray';

const mapClaims = (damagesList: Damage[], type: DamageType): Claim[] => {
  return damagesList.map((damage) => ({
    reason: damage.reason as ClaimType,
    month: asInt(damage.month),
    year: asInt(damage.year),
    amount: asInt(damage.amount),
    type: getDamageType(type),
  }));
};

export const mapHouseholdInquiryToRequest = (
  inquiry,
  language: Language,
  isInternalIPAddress: boolean,
  isMobiliarWhiteLabel: boolean,
  profileEmail?: string,
) => {
  return {
    language,
    isInternalIPAddress,
    identifier: inquiry.identifier,
    previousInsuranceCompany: inquiry[Field.PreviousInsurer],
    insuranceStartDate: new Date(inquiry[Field.InsuranceStartDate]!),
    insuranceSum: inquiry[Field.InsuranceSum],
    recommendedInsuranceSum: inquiry[Field.RecommendedInsuranceSum],
    householdProperty: {
      possessionType: inquiry[Field.PossessionType],
      householdType: inquiry[Field.HouseholdType],
      householdOccupancyType: inquiry[Field.HouseholdOccupancyType],
      isMassive: inquiry[Field.IsMassive],
      isGroundFloorApartment: inquiry[Field.IsGroundFloorApartment],
      numberOfRooms: inquiry[Field.NumberOfRooms],
      city: inquiry[Field.City],
    },
    householdContent: {
      furnishing: inquiry[Field.Furnishing],
      clothing: inquiry[Field.Clothing],
      electricalAppliances: inquiry[Field.ElectricalAppliances],
    },
    coverage: {
      privateLiability: inquiry[Field.PrivateLiability],
      theftNotAtHome: inquiry[Field.TheftNotAtHome],
      luggageInsurance: inquiry[Field.LuggageInsurance],
      glassFurniture: inquiry[Field.GlassFurniture],
      cyberInsurance: inquiry[Field.CyberInsurance],
      selfInflictedDamages: inquiry[Field.SelfInflictedDamages],
      theftNotAtHomeAmount: inquiry[Field.TheftNotAtHomeAmount],
      selfInflictedDamagesAmount: inquiry[Field.SelfInflictedDamagesAmount],
    },
    damages: {
      contractTerminatedByPreviousInsurer: inquiry[Field.ContractTerminatedByPreviousInsurer] || false,
      reasonForCancellation: inquiry[Field.ReasonForCancellation],
      claims: [
        ...mapClaims(inquiry[Field.HouseholdDamages], DamageType.Household),
        ...mapClaims(inquiry[Field.PrivateLiabilityDamages], DamageType.PrivateLiability),
      ],
    },
    personalDetails: {
      profileEmail,
      emailAddress: inquiry[Field.EmailAddress],
      dateOfBirth: new Date(inquiry[Field.DateOfBirth] as string),
      numberOfInsurees: parseInt(inquiry[Field.NumberOfInsurees]!),
      numberOfDogs: inquiry[Field.NumberOfDogs] === null ? 0 : inquiry[Field.NumberOfDogs],
      children: inquiry[Field.ChildrenDatesOfBirth].map((date) => ({
        dateOfBirth: new Date(date as string),
      })),
    },
    nonOwnerDriverInsurance: inquiry[Field.NonOwnerDriverInsurance],
    grossNegligence: inquiry[Field.GrossNegligence],
    isMobiliarWhiteLabel,
  };
};

export const mapInquiryFromQuickAdjustToRequest = (
  inquiry,
  quickAdjustFields,
  language: Language,
  isInternalIP: boolean,
  isMobiliarWhiteLabel: boolean,
) => {
  const mappedInquiry = mapHouseholdInquiryToRequest(inquiry, language, isInternalIP, isMobiliarWhiteLabel);
  let theftNotAtHomeAmount = mappedInquiry.coverage.theftNotAtHomeAmount;
  let selfInflictedDamagesAmount = mappedInquiry.coverage.selfInflictedDamagesAmount;

  if (quickAdjustFields.theftNotAtHome) {
    theftNotAtHomeAmount = theftNotAtHomeAmount === null ? DEFAULT_THEFT_NOT_AT_HOME_AMOUNT : theftNotAtHomeAmount;
  } else {
    theftNotAtHomeAmount = null;
  }
  if (quickAdjustFields.selfInflictedDamages) {
    selfInflictedDamagesAmount =
      selfInflictedDamagesAmount === null ? DEFAULT_SELF_INFLICTED_DAMAGES_AMOUNT : selfInflictedDamagesAmount;
  } else {
    selfInflictedDamagesAmount = null;
  }
  const insuranceDateIsInPast = validateDateIsInFuture(inquiry.insuranceStartDate);
  return {
    ...mappedInquiry,
    insuranceStartDate: insuranceDateIsInPast
      ? new Date(getDefaultInsuranceStartDate(new Date()))
      : new Date(inquiry.insuranceStartDate),
    insuranceSum: quickAdjustFields.insuranceSum,
    householdContent: {
      ...mappedInquiry.householdContent,
    },
    coverage: {
      ...mappedInquiry.coverage,
      cyberInsurance: quickAdjustFields.cyberInsurance,
      luggageInsurance: quickAdjustFields.luggageInsurance,
      theftNotAtHome: quickAdjustFields.theftNotAtHome,
      selfInflictedDamages: quickAdjustFields.selfInflictedDamages,
      glassFurniture: quickAdjustFields.glassFurniture,
      theftNotAtHomeAmount,
      selfInflictedDamagesAmount,
    },
  };
};

export const mapPrivateLiabilityInquiryToRequest = (
  inquiry: Readonly<PrivateLiabilityFields>,
  languageCode: Language,
  isInternalIPAddress: boolean,
  profileEmail?: string,
) => {
  const result: PrivateLiabilityInquiryRequest = {
    dataProtectionAccepted: inquiry.dataProtectionAccepted,
    possessionType: inquiry.possessionType!,
    householdType: inquiry.householdType!,
    grossNegligence: inquiry.grossNegligence!,
    nonOwnerDriverInsurance: inquiry.nonOwnerDriverInsurance!,
    city: inquiry.city,
    isInternalIPAddress: isInternalIPAddress,
    insuranceStartDate: new Date(String(inquiry.insuranceStartDate)),
    language: languageCode,
    previousInsuranceCompany: inquiry.previousInsurer!,
    personalDetails: {
      profileEmail: profileEmail,
      emailAddress: inquiry.emailAddress!,
      dateOfBirth: new Date(String(inquiry.dateOfBirth)),
      numberOfInsurees: inquiry.numberOfInsurees!,
      children: inquiry.childrenDatesOfBirth.map((birthDate) => ({ dateOfBirth: new Date(birthDate) })),
      numberOfDogs: inquiry.numberOfDogs === null ? 0 : asInt(inquiry.numberOfDogs),
    },
    damages: {
      reasonForCancellation: inquiry.reasonForCancellation,
      claims: mapClaims(inquiry.privateLiabilityDamages, DamageType.PrivateLiability),
    },
  };
  return result;
};
