import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { DogPaw } from '../../Icon';
import { BooleanSelection } from '../../QuestionTypes';
import { BooleanQuestionProps } from '../interfaces';

export const IsDogOwnerQuestion: React.FC<BooleanQuestionProps> = (props) => {
  const t = useTranslations();

  return (
    <BooleanSelection
      errorMessage={props.error ? t('isDogOwnerError') : undefined}
      name={Field.IsDogOwner}
      title={t('isDogOwnerQuestion')}
      trueLabel={t('yesOption')}
      falseLabel={t('noOption')}
      trueIcon={<DogPaw />}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
