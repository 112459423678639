import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { getPriceOptions } from '../../InquirySections/HouseholdContentSection/getPriceOptions';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const FurniturePriceQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const furniturePriceOptions = getPriceOptions(
    t('furniturePriceLowLabel'),
    t('furniturePriceMediumLabel'),
    t('furniturePriceHighLabel'),
  );

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('furniturePriceError') : undefined}
      name={Field.Furnishing}
      title={t('furniturePriceQuestion')}
      intro={t('furniturePriceIntro')}
      options={furniturePriceOptions}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
