import * as React from 'react';
import { Field, OccupancyType } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const HouseholdOccupancyQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const occupancyOptions = [
    { value: OccupancyType.PermanentlyOccupied, label: t('permanentlyOccupiedLabel') },
    { value: OccupancyType.SometimesOccupied, label: t('sometimesOccupiedLabel') },
    { value: OccupancyType.Unoccupied, label: t('unOccupiedLabel') },
  ];

  return (
    <SingleChoiceSelection
      errorMessage={undefined}
      name={Field.HouseholdOccupancyType}
      intro={t('householdOccupancyQuestionIntro')}
      options={occupancyOptions}
      title={t('householdOccupancyQuestion')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
