import { AxiosResponse } from 'axios';
import { logger } from '../common/log/logger';
import { City } from '../types';
import { Canton } from '../enums';
import { locationsApiClient } from '../lib/apiClients/locationsApiClient';

export interface CityResponse {
  canton: string;
  bfsNumber: number;
  name: string;
  zipCode: number;
  zipCodeAddition: string;
}

/**
 * Retrieve city details for the given postal code.
 * @param postalCode
 */
export const getCities = async (postalCode: number): Promise<City[]> => {
  try {
    const response: AxiosResponse<CityResponse[]> = await locationsApiClient.get(`/cities/${postalCode}`);
    return response.data.map((city) => ({
      postalCode: city.zipCode,
      name: city.name,
      canton: city.canton as Canton,
    }));
  } catch (error) {
    logger.error('Unable to fetch location info for', postalCode, '-', error.message);
    throw error;
  }
};
