import * as React from 'react';
import { range } from '@scout24ch/fs24-utils';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes/SelectInput';
import { DropDownQuestionProps } from '../interfaces';

interface YearOfDamageQuestionProps {
  fieldName: string;
}

export const YearOfDamageQuestion = (props: DropDownQuestionProps & YearOfDamageQuestionProps) => {
  const currentYear = new Date().getFullYear();

  const t = useTranslations();

  const getYearOptions = (): SelectQuestionOption[] => {
    return [
      { label: t('selectLabel'), value: '', disabled: true },
      ...range(6, (i) => String(currentYear - i))
        .map((year) => ({ label: year, value: year }))
        .reverse(),
    ];
  };

  return (
    <SelectInput
      errorMessage={props.error ? t('dateError') : undefined}
      name={props.fieldName}
      title={t('yearQuestion')}
      options={getYearOptions()}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
