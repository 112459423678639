import * as React from 'react';
import { PriceRange } from '../../../enums';
import { CoinSmall, CoinsSmall, MoneyBag } from '../../Icon';

export const getPriceOptions = (low: string, medium: string, high: string) => {
  return [
    { value: PriceRange.Low, label: low, icon: <CoinSmall /> },
    { value: PriceRange.Medium, label: medium, icon: <CoinsSmall /> },
    { value: PriceRange.High, label: high, icon: <MoneyBag /> },
  ];
};
