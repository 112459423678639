import {
  conditionalFieldHasError,
  fieldHasError,
  getChildrenBirthDatesErrors,
  getCityErrors,
  getDamageError,
  getDateOfBirthError,
  getEmailError,
  hasError,
  numberOfChildrenIsValid,
} from '../../components/householdInsuranceValidators';
import { insuranceStartDateValidator } from '../../components/InquirySections/InsuranceStartDate/insuranceStartDate.validator';
import { Field } from '../../enums';
import { CityError, DamageError } from '../../types';
import { PrivateLiabilityInputFieldsError } from '../privateLiabilityError/types';
import { PrivateLiabilityFields } from './types';

export const privateLiabilityInquiryValidator = (
  inquiryFields: PrivateLiabilityFields,
  errors: PrivateLiabilityInputFieldsError,
) => {
  const updatedErrors: PrivateLiabilityInputFieldsError = { ...errors };

  updatedErrors.possessionType = hasError(inquiryFields.possessionType);
  updatedErrors.householdType = hasError(inquiryFields.householdType);
  updatedErrors.grossNegligence = hasError(inquiryFields.grossNegligence);
  updatedErrors.nonOwnerDriverInsurance = hasError(inquiryFields.nonOwnerDriverInsurance);
  updatedErrors.dateOfBirth = getDateOfBirthError(inquiryFields.dateOfBirth, updatedErrors.dateOfBirth);
  updatedErrors.isDogOwner = hasError(inquiryFields.isDogOwner);
  updatedErrors.numberOfDogs = conditionalFieldHasError(inquiryFields.isDogOwner, inquiryFields.numberOfDogs);
  updatedErrors.numberOfInsurees = hasError(inquiryFields.numberOfInsurees);
  updatedErrors.numberOfChildren = !numberOfChildrenIsValid(
    inquiryFields.numberOfInsurees,
    inquiryFields.numberOfChildren,
  );
  updatedErrors.childrenDatesOfBirth = getChildrenBirthDatesErrors(
    inquiryFields.childrenDatesOfBirth,
    updatedErrors.childrenDatesOfBirth,
  );
  updatedErrors.city = getCityErrors(inquiryFields.city, errors.city);
  updatedErrors.emailAddress = getEmailError(inquiryFields.emailAddress);
  updatedErrors.insuranceStartDate = insuranceStartDateValidator(
    String(inquiryFields.insuranceStartDate),
    errors.insuranceStartDate,
  );
  updatedErrors.compensationForPrivateLiability = hasError(inquiryFields.compensationForPrivateLiability);
  updatedErrors.numberOfPrivateLiabilityCompensations = conditionalFieldHasError(
    inquiryFields.compensationForPrivateLiability,
    inquiryFields.numberOfPrivateLiabilityCompensations,
  );
  updatedErrors.privateLiabilityDamages = inquiryFields.privateLiabilityDamages.map((damage) => getDamageError(damage));
  updatedErrors.previousInsurer = hasError(inquiryFields.previousInsurer);
  updatedErrors.contractTerminatedByPreviousInsurer = hasError(inquiryFields.contractTerminatedByPreviousInsurer);
  updatedErrors.reasonForCancellation = conditionalFieldHasError(
    inquiryFields.contractTerminatedByPreviousInsurer,
    inquiryFields.reasonForCancellation,
  );
  updatedErrors.dataProtectionAccepted = inquiryFields.dataProtectionAccepted === false;

  return updatedErrors;
};

export const hasDamagesErrors = (damages: DamageError[]) => {
  return damages.some((damage) => Object.values(damage).some(fieldHasError));
};

export const hasCityError = (city: CityError) => {
  return !Object.values(city).every((field) => field === false || field === null);
};

export const privateLiabilityInquiryHasErrors = (errors: PrivateLiabilityInputFieldsError) => {
  const fields = {
    [Field.City]: hasCityError(errors.city),
    [Field.DateOfBirth]: errors.dateOfBirth !== null,
    [Field.ChildrenDatesOfBirth]: errors.childrenDatesOfBirth.some((date) => date !== null),
    [Field.EmailAddress]: errors.emailAddress !== null,
    [Field.InsuranceStartDate]: errors.insuranceStartDate !== null,
    [Field.PrivateLiabilityDamages]: hasDamagesErrors(errors.privateLiabilityDamages),
  };

  const updatedErrors = { ...errors, ...fields };
  return Object.values(updatedErrors).some(fieldHasError);
};
