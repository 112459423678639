import * as React from 'react';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SelectInput } from '../../QuestionTypes';
import { DropDownQuestionProps } from '../interfaces';
import { Field, ClaimType } from '../../../enums';

interface CompensationReasonQuestionProps {
  compensationType: string;
  fieldName: string;
}

export const DamageReasonQuestion: React.FC<DropDownQuestionProps & CompensationReasonQuestionProps> = (props) => {
  const t = useTranslations();

  const householdDamageOptions: SelectQuestionOption[] = [
    { label: t('selectLabel'), value: '', disabled: true },
    { value: ClaimType.Theft, label: t(ClaimType.Theft) },
    { value: ClaimType.Fire, label: t(ClaimType.Fire) },
    { value: ClaimType.ActsOfGod, label: t(ClaimType.ActsOfGod) },
    { value: ClaimType.Burglary, label: t(ClaimType.Burglary) },
    { value: ClaimType.Robbery, label: t(ClaimType.Robbery) },
    { value: ClaimType.Flooding, label: t(ClaimType.Flooding) },
    { value: ClaimType.Glass, label: t(ClaimType.Glass) },
  ];

  const privateLiabilityOptions: SelectQuestionOption[] = [
    { label: t('selectLabel'), value: '', disabled: true },
    {
      value: ClaimType.RentersDamage,
      label: t(ClaimType.RentersDamage),
    },
    {
      value: ClaimType.BodilyInjury,
      label: t(ClaimType.BodilyInjury),
    },
    {
      value: ClaimType.PropertyDamage,
      label: t(ClaimType.PropertyDamage),
    },
  ];

  return (
    <SelectInput
      errorMessage={props.error ? t('compensationReasonError') : undefined}
      name={props.fieldName}
      title={t('reasonQuestion')}
      options={props.compensationType === Field.HouseholdDamages ? householdDamageOptions : privateLiabilityOptions}
      value={props.currentValue || undefined}
      onChange={props.onChange}
    />
  );
};
