import * as React from 'react';
import { SelectQuestionOption } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';
import { Field } from '../../../enums';

export const NumberOfDogsQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const numberOfDogsOptions: SelectQuestionOption[] = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: t('orMore', { num: '3' }) },
  ];

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('numberOfDogsError') : undefined}
      name={Field.NumberOfDogs}
      title={t('numberOfDogsQuestion')}
      options={numberOfDogsOptions}
      currentValue={props.currentValue}
      onChange={props.onChange}
    />
  );
};
