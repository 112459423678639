import * as React from 'react';
import { DateQuestion } from '@scout24ch/fs24-design-system';
import { validateDateIfLessThanXYears, validateDateNotInFuture } from '@scout24ch/fs24-forms';
import { range } from '@scout24ch/fs24-utils';
import { DateError, Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { scrollTo } from '../../../utils';
import { TranslationKey } from '../../../types';

interface ChildrenBirthDatesProps {
  numberOfChildren: number;
  onError: (error: DateError | null, index: number) => void;
  onChange: (newValue: string, index: number) => void;
  errors: Readonly<DateError | null>[];
  currentValues: string[];
  nextField: Field | string;
}

export const ChildrenBirthDatesQuestion: React.FC<ChildrenBirthDatesProps> = (props) => {
  const t = useTranslations();

  const handleDateChange = (value: string, index: number) => {
    if (value === '' || value.split('-').length !== 3) {
      props.onError(DateError.GeneralDateError, index);
      return;
    } else {
      const isInFuture = validateDateNotInFuture(value);
      const moreThan18YearsError = validateDateIfLessThanXYears(value, 18);
      if (moreThan18YearsError || isInFuture) {
        props.onError(DateError.DateOfBirthOver18, index);
      } else {
        props.onError(null, index);
      }
    }
    props.onChange(value, index);
  };

  return (
    // Key is needed to rerender date input field to reset the value of the Input type="date" when changing the amount of children
    <React.Fragment key={props.numberOfChildren}>
      {range(props.numberOfChildren || 0, (index) => (
        <div className="children-date-of-birth" key={index}>
          <DateQuestion
            label={index === 0 ? t('childrenDateOfBirthTitle') : undefined}
            description={`${index + 1}. ${t('childLabel')}`}
            name={`child-dateOfBirth-input-${index}`}
            errorMessage={!!props.errors[index] ? t(props.errors[index] as TranslationKey) : undefined}
            isInvalid={!!props.errors[index]}
            value={props.currentValues[index] || ''}
            onValueChange={(e) => {
              handleDateChange(e, index);
              if (index === props.currentValues.length - 1) {
                scrollTo(props.nextField);
              }
            }}
          />

          {index === props.currentValues.length - 1 ? (
            <div className="scroll-checkpoint" data-scroll={Field.ChildrenDatesOfBirth} />
          ) : null}
        </div>
      ))}
    </React.Fragment>
  );
};
