import { HouseHome, BuildingSemiDetachedHouse } from '@scout24ch/fs24-design-system/icons/24';
import * as React from 'react';
import { Field, HouseholdType } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const HouseholdTypeQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const householdTypeOptions = [
    {
      value: HouseholdType.DetachedHouse,
      label: t('householdTypeDetachedHouseLabel'),
      icon: <HouseHome />,
    },
    {
      value: HouseholdType.Apartment,
      label: t('householdTypeApartmentLabel'),
      icon: <BuildingSemiDetachedHouse />,
    },
  ];

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('generalError') : undefined}
      name={Field.HouseholdType}
      options={householdTypeOptions}
      title={t('householdTypeQuestion')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
