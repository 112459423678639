import * as React from 'react';
import { validateDateIfMoreThanXMonths, validateDateIsInFuture } from '@scout24ch/fs24-forms';
import { DateQuestion } from '@scout24ch/fs24-design-system';
import { DateError, Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { getDefaultInsuranceStartDate } from '../../../utils';
import { RenderIf } from '../../RenderIf';
import { TranslationKey } from '../../../types';

interface InsuranceStartDateQuestionProps {
  currentValue: string | null;
  onChange: (newDate: string) => void;
  onError: (error: DateError | null) => void;
  error: DateError | null;
}

export const InsuranceStartDateQuestion: React.FC<InsuranceStartDateQuestionProps> = (props) => {
  const t = useTranslations();

  React.useEffect(() => {
    if (!props.currentValue) {
      props.onChange(getDefaultInsuranceStartDate(new Date()));
    }
    // should only happen on mount
    //eslint-disable-next-line
  }, []);

  const handleDateChange = (newDate: string) => {
    if (newDate === '') {
      props.onError(DateError.InsuranceStartDateMissing);
    } else {
      const insuranceDateIsInPast = validateDateIsInFuture(newDate);
      const moreThanOneYearInTheFuture = validateDateIfMoreThanXMonths(newDate, -12);
      if (insuranceDateIsInPast) {
        props.onError(DateError.DateToDayOrInThePast);
      } else if (moreThanOneYearInTheFuture) {
        props.onError(DateError.DateOverOneYearInTheFuture);
      } else {
        props.onError(null);
      }
    }
    props.onChange(newDate);
  };

  return (
    <RenderIf condition={props.currentValue !== null}>
      <DateQuestion
        label={t('insuranceStartDateQuestion')}
        name={Field.InsuranceStartDate}
        errorMessage={props.error ? t(props.error as TranslationKey) : undefined}
        isInvalid={!!props.error}
        placeholder={t('datePlaceHolder')}
        value={props.currentValue || ''}
        onValueChange={handleDateChange}
      />
    </RenderIf>
  );
};
