import * as React from 'react';
import { Field, PossessionType } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const PossessionTypeQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const possessionTypeOptions = [
    {
      value: PossessionType.Rented,
      label: t('householdPosessionTypeRentedLabel'),
    },
    {
      value: PossessionType.Owned,
      label: t('householdPosessionTypeOwnedLabel'),
    },
  ];

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('generalError') : undefined}
      name={Field.PossessionType}
      options={possessionTypeOptions}
      title={t('householdPosessionTypeQuestion')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
