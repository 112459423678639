import * as React from 'react';
import { range } from '@scout24ch/fs24-utils';
import { DamageAmountQuestion, DamageReasonQuestion, MonthOfDamageQuestion, YearOfDamageQuestion } from '../';
import { useTranslations } from '../../../hooks';
import { Damage } from '../../../types/Damage';
import { DamageError } from '../../../types';
import { ClaimType, DamageReason } from '../../../enums';
import { asInt, scrollTo } from '../../../utils';
import { RenderIf } from '../../RenderIf';

interface DamageDetailsProps {
  numberOfDamages: number;
  name: string;
  nextField: string;
  damages: Damage[];
  errors: DamageError[];
  onChange: (newValue: Damage, index: number) => void;
}

export const DamageDetailsQuestion: React.FC<DamageDetailsProps> = (props) => {
  const t = useTranslations();

  const handleYearChange = (newValue: string, index: number) => {
    if (props.damages[index].month !== null && asInt(props.damages[index].month) > new Date().getMonth() + 1) {
      props.onChange({ ...props.damages[index], [DamageReason.Month]: newValue }, index);
    }
    props.onChange({ ...props.damages[index], [DamageReason.Year]: newValue }, index);
    scrollTo(`${props.name}-${DamageReason.Month}-${index}`);
  };

  const handleMonthChange = (newValue: string, index: number) => {
    props.onChange({ ...props.damages[index], [DamageReason.Month]: newValue }, index);
    const next =
      index === props.numberOfDamages - 1 ? props.nextField : `${props.name}-${DamageReason.Reason}-${index + 1}`;
    scrollTo(next);
  };

  return (
    <>
      {range(props.numberOfDamages || 0, (damageInfo, index) => (
        <React.Fragment key={index}>
          <RenderIf condition={index === 0 || props.damages[index - 1].month !== null}>
            <h2 className="damage-title">{props.numberOfDamages > 1 ? `${t('damage')} ${index + 1}` : null}</h2>
            <DamageReasonQuestion
              error={props.errors[index] && props.errors[index].reason}
              compensationType={props.name}
              currentValue={props.damages[index].reason}
              fieldName={`${props.name}-${DamageReason.Reason}-${index}`}
              onChange={(newValue) => {
                props.onChange({ ...props.damages[index], [DamageReason.Reason]: newValue as ClaimType }, index);
                scrollTo(`${props.name}-${DamageReason.Year}-${index}`);
              }}
            />
          </RenderIf>
          <RenderIf condition={props.damages[index].reason !== null || props.damages[index].year !== null}>
            <DamageAmountQuestion
              id={`damages${props.name}-${index}-amount`}
              error={props.errors[index] && props.errors[index].amount}
              currentValue={props.damages[index].amount}
              onChange={(newAmount) =>
                props.onChange({ ...props.damages[index], [DamageReason.Amount]: newAmount }, index)
              }
            />
            <p className="damage-date">{t('timeOfDamageQuestion')}</p>
            <YearOfDamageQuestion
              error={props.errors[index] && props.errors[index].year}
              fieldName={`${props.name}-${DamageReason.Year}-${index}`}
              currentValue={props.damages[index].year}
              onChange={(newValue) => handleYearChange(newValue, index)}
            />
          </RenderIf>
          <RenderIf condition={props.damages[index].year !== null || props.damages[index].month !== null}>
            <MonthOfDamageQuestion
              error={props.errors[index] && props.errors[index].month}
              fieldName={`${props.name}-${DamageReason.Month}-${index}`}
              year={String(props.damages[index].year)}
              currentValue={props.damages[index].month}
              onChange={(newValue: string) => handleMonthChange(newValue, index)}
            />
          </RenderIf>
        </React.Fragment>
      ))}
    </>
  );
};
