import * as React from 'react';
import { Field, InsuranceCancellationReason } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { SingleChoiceSelection } from '../../QuestionTypes';
import { MultipleChoiceQuestionProps } from '../interfaces';

export const ReasonForCancellationQuestion: React.FC<MultipleChoiceQuestionProps> = (props) => {
  const t = useTranslations();

  const reasonForCancellationOptions = [
    { value: InsuranceCancellationReason.DifficultConditions, label: t('difficultConditionsLabel') },
    { value: InsuranceCancellationReason.ApplicationRejected, label: t('applicationRejectedLabel') },
    {
      value: InsuranceCancellationReason.CancelledByInsuranceCompany,
      label: t('cancelledByInsuranceCompanyLabel'),
    },
    { value: InsuranceCancellationReason.CancelledByUser, label: t('cancelledByUserLabel') },
  ];
  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('reasonForCancellationError') : undefined}
      name={Field.ReasonForCancellation}
      title={t('reasonForCancellationQuestion')}
      options={reasonForCancellationOptions}
      currentValue={props.currentValue}
      onChange={props.onChange}
    />
  );
};
