import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '../../common/environment';
import { cloudFlareInterceptor } from '../cloudFlare/cloudFlareInterceptor';

const isProd = process.env.NODE_ENV === 'production';
const isTest = process.env.NODE_ENV === 'test';

export const apiLocationsURL =
  isProd || isTest ? `${environment.HOUSEHOLD_APP_HOST}/api/location-service/api/v1` : process.env.LOCATION_SERVICE_URL;

const config: AxiosRequestConfig = {
  baseURL: apiLocationsURL,
};

if (isProd) {
  config.withCredentials = true;
}

const locationsApiClient = axios.create(config);

locationsApiClient.interceptors.request.use(cloudFlareInterceptor);

export { locationsApiClient };
