import * as React from 'react';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { asInt } from '../../../utils/asInt';
import {
  SingleChoiceSelection,
  SingleChoiceSelectionOption,
} from '../../QuestionTypes/SingleChoiceSelection/SingleChoiceSelection';

interface NumberOfChildrenQuestionProps {
  error: boolean;
  onChange: (newValue: SingleChoiceSelectionOption) => void;
  currentValue: string | null;
  numberOfInsurees: number | string | null;
}

export const NumberOfChildrenQuestion: React.FC<NumberOfChildrenQuestionProps> = (props) => {
  const t = useTranslations();

  const getNumberOfResidentsUnder16Options = () => {
    const numberOfInsurees = props.numberOfInsurees ? asInt(String(props.numberOfInsurees)) : null;
    if (numberOfInsurees && numberOfInsurees > 1) {
      return [
        { value: '0', label: t('none') },
        { value: '1', label: `1 ${t('personSingular')}` },
        ...Array.from(Array(numberOfInsurees ? numberOfInsurees - 2 : 0), (x, i) => ({
          value: String(i + 2),
          label: `${i + 2} ${t('personPlural')}`,
        })),
      ];
    }
    return [];
  };

  return (
    <SingleChoiceSelection
      errorMessage={props.error ? t('numberOfResidentsAgedUnder16Error') : undefined}
      name={Field.NumberOfResidentsAgedUnder16}
      title={t('numberOfResidentsAgedUnder16Question')}
      options={getNumberOfResidentsUnder16Options()}
      currentValue={props.currentValue}
      onChange={props.onChange}
    />
  );
};
