import * as React from 'react';
import { Grid, GridItem, Question, CurrencyInput } from '@scout24ch/fs24-design-system';
import { useTranslations } from '../../../hooks';

interface DamageAmountQuestionProps {
  id: string;
  currentValue: string | null;
  onChange: (newValue: string) => void;
  error: boolean;
}

export const DamageAmountQuestion: React.FC<DamageAmountQuestionProps> = (props) => {
  const t = useTranslations();

  return (
    <Question label={t('amountQuestion')} name="amountQuestion">
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={6}>
          <CurrencyInput
            id={props.id}
            name={props.id}
            value={props.currentValue || ''}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </GridItem>
      </Grid>
    </Question>
  );
};
