import * as React from 'react';
import { useTrackOnMount } from '@scout24ch/fs24-gtm-react';
import { DataLayerGtmEvent } from '../../../config/gtm/helpers';
import { Field } from '../../../enums';
import { useTranslations } from '../../../hooks';
import { BooleanSelection } from '../../QuestionTypes';
import { BooleanQuestionProps } from '../interfaces';

interface GrossNegligenceQuestionProps extends BooleanQuestionProps {
  eventOnLoad?: DataLayerGtmEvent;
}

export const GrossNegligenceQuestion: React.FC<GrossNegligenceQuestionProps> = (props) => {
  const t = useTranslations();
  useTrackOnMount(props.eventOnLoad as DataLayerGtmEvent);

  return (
    <BooleanSelection
      errorMessage={props.error ? t('grossNegligenceError') : undefined}
      title={t('grossNegligenceTitle')}
      intro={t('grossNegligenceQuestion')}
      name={Field.GrossNegligence}
      trueLabel={t('yesOption')}
      falseLabel={t('noOption')}
      onChange={props.onChange}
      currentValue={props.currentValue}
    />
  );
};
